import sonsLogo from '../Assets/images/logo.svg';
import React from "react";
import Buttons from './Control/Buttons';
import Form from './Form';
import Success from './Success';
import Failure from './Failure';
import {Dialog, Box} from "@mui/material";
import Explainer from './Form/Explainer';
import {Dna} from  'react-loader-spinner'

export default class ManualForm extends React.Component
{
    constructor(props) {
        super(props);
        this.state = {
            showDrivingLicense: false,
            showPassPort: false,
            hasFinished: false,
            showModal: true,
            visibleLoader: false,
            setFailure: false
        }

        this.showPassport = this.showPassport.bind(this);
        this.showDrivingLicense = this.showDrivingLicense.bind(this);
        this.setFinished = this.setFinished.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.showLoader = this.showLoader.bind(this);
        this.hideLoader = this.hideLoader.bind(this);
    }

    showLoader = () => {
        this.setState({ visibleLoader: true });
    };

    hideLoader = () => {
        this.setState({ visibleLoader: false });
    };

    setFinished = () => {
        this.setState({ hasFinished: true });
    };

    setFailure = () => {
        this.setState({ setFailure: true });
    };

    showPassport = () => {
        this.setState({ showDrivingLicense: true, showPassPort: false });
    };

    showDrivingLicense = () => {
        this.setState({ showDrivingLicense: false, showPassPort: true });
    };

    handleClose = () => {}

    render() {
        return (
        <Box>
            {!this.state.hasFinished && !this.state.setFailure && (
                <Dialog
                    open={this.state.showModal}
                    onClose={this.handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                    className="sons-content-wrapper"
                >
                    {this.state.visibleLoader && (
                        <div class="loader-wrapper">
                            <div class="loader-wrapper-text">
                                <Dna
                                    visible={this.state.visibleLoader}
                                    height="150"
                                    width="150"
                                    ariaLabel="dna-loading"
                                    wrapperStyle={{}}
                                    wrapperClass="dna-wrapper"
                                />
                            </div>
                        </div>
                    )}
                    <Box maxWidth="sm" className="sons-contents">
                        <Box className="sons-logo-container">
                            <img class="sons-logo" src={sonsLogo} title="Sons UK" alt="Sons UK" height="80" />
                        </Box>
                        <h1>IDENTITY VERIFICATION</h1>
                        <h3 class="h3-bottom">SECURE ENVIRONMENT</h3>
                        <Box>
                            <Explainer /><br /><br />
                            <Buttons showDrivingLicense={this.showDrivingLicense} showPassPort={this.showPassport} />
                            <Form showDrivingLicense={this.state.showDrivingLicense}
                                  showPassPort={this.state.showPassPort}
                                  setFinished={this.setFinished}
                                  showLoader={this.showLoader}
                                  hideLoader={this.hideLoader}
                                  setFailure={this.setFailure} />
                        </Box>
                    </Box>
                </Dialog>
            )} {this.state.hasFinished && !this.state.setFailure && (
                <div>
                    <Success showModal={this.state.showModal} onClose={this.handleClose} />
                </div>
            )} {this.state.setFailure && (
                <div>
                    <Failure showModal={this.state.showModal} onClose={this.handleClose} />
                </div>
            )}
        </Box>
        );
    }
}