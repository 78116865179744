import React from 'react';
import {Dialog, Box} from "@mui/material";
import sonsLogo from "../Assets/images/logo.svg";

const Failure = (showModal, onClose) =>
{
    return (
        <Dialog
            open={showModal}
            onClose={onClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box maxWidth="sm" class="failure-box">
                <Box className="sons-logo-container">
                    <img class="sons-logo" src={sonsLogo} title="Sons UK" alt="Sons UK" height="80" />
                </Box>
                <h3>UNABLE TO VALIDATE YOUR IDENTITY</h3>
                <Box className="failure-container">
                    Unfortunately, the validation system was unable to verify your identity.<br/>
                    Please contact Son's customer success team to confirm your order:<br/><br/>
                    <a class="email-text" href="mailto:hello@sons.co.uk">hello@sons.co.uk</a>
                </Box>
            </Box>
        </Dialog>
    )
}

export default Failure;