import './Assets/css/App.css';
import './Assets/css/styles.css';
import ManualForm from './Model/ManualForm';
import React, { useEffect } from 'react';
import { ThemeProvider, createTheme } from '@mui/system';
import {ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

const AppContext = React.createContext(undefined);

const theme = createTheme({
    palette: {
        background: {
            paper: 'black',
        },
        text: {
            primary: '#173A5E',
            secondary: '#46505A',
        },
        action: {
            active: '#001E3C',
        },
        success: {
            dark: '#009688',
        },
    },
    zIndex: {
        modal: 1250,
    },
    transitions: {
        duration: {
            shortest: 150,
            shorter: 200,
            short: 250,
            standard: 300,
            complex: 375,
            enteringScreen: 225,
            leavingScreen: 195,
        },
        transition: 'fade',
        create: function (props, options) {}
    },
    shadows: {
        24: ''
    },
    typography: {
        button: {

        }
    }
});

function App() {
    useEffect(() => {
        document.title = 'Sons UK - Verification';
    }, []);

  return (
      <ThemeProvider theme={theme}>
          <ToastContainer
              position="top-right"
              autoClose={5000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
              theme="light"
          />
          <ManualForm AppContext={AppContext} />
      </ThemeProvider>
  );
}

export default App;
