import React from "react";
import Container from '@mui/material/Container';

const Buttons = ({ showDrivingLicense, showPassPort }) =>
{
    return (
        <Container class="button-container">
            <button variant="outlined" id="passport" className="passport" onClick={showDrivingLicense}>
                <span>PASSPORT</span>
            </button>
            <button variant="outlined" id="driving_license" className="driving_license" onClick={showPassPort}>
                <span>DRIVING LICENSE</span>
            </button>
        </Container>
    );
}

export default Buttons;