import React from 'react';
import {Dialog, Box} from "@mui/material";
import sonsLogo from "../Assets/images/logo.svg";

const Success = (showModal, onClose) =>
{
    return (
        <Dialog
            open={showModal}
            onClose={onClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box maxWidth="sm" class="success-box">
                <Box className="sons-logo-container">
                    <img class="sons-logo" src={sonsLogo} title="Sons UK" alt="Sons UK" height="80" />
                </Box>
                <h3>VALIDATION COMPLETE</h3>
                <Box className="success-container">
                    Thank you for verifying your information, we were able to successfully validate your identity.<br/>
                    You will receive an email shortly, confirming this.<br/>
                    Thank you for your business,<br/>
                    The Sons team<br/>
                </Box>
            </Box>
        </Dialog>
    )
}

export default Success;