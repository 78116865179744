import React from 'react';
import DrivingLicense from './Form/DrivingLicense';
import Passport from './Form/Passport';

const Form = ({ showDrivingLicense, showPassPort, setFinished, showLoader, hideLoader, setFailure }) =>
{
    return (
        <div className="button-wrapper">
            {showPassPort
                && <Passport setFinished={setFinished}
                             showLoader={showLoader}
                             hideLoader={hideLoader}
                             setFailure={setFailure}
                />
            }
            {showDrivingLicense
                && <DrivingLicense setFinished={setFinished}
                                   showLoader={showLoader}
                                   hideLoader={hideLoader}
                                   setFailure={setFailure}
                />
            }
        </div>
    )
}

export default Form;