import React from 'react';

const Explainer = () =>
{
    return (
        <div className="explainer">
            <span className="text">Please enter your driving license number or passport number, please see the photo id page of your passport, or your driving license.</span><br/><br/>
            <span className="text"><strong>Note:</strong> that all identity data is immediately destroyed upon verification completion, and no human can see this data.</span>
        </div>
    )
}

export default Explainer;