import React, { useState, useMemo, useImperativeHandle } from 'react';
import Select from 'react-select';
import countryList from 'react-select-country-list';

const CountrySelect = React.forwardRef((props, ref) => {
    const [value, setValue] = useState('');
    const options = useMemo(() => countryList().getData(), []);
    useImperativeHandle(ref, () => ({getSelectedValue: () => { return value }}), [value]);

    const changeHandler = value => {
        setValue(value)
    }

    return (
        <label htmlFor="countrySelector" style={{marginTop: 10, display: 'block'}}>
            <span>Passport Issuing Country</span>
            <Select
                id="countrySelector"
                options={options}
                value={value}
                menuPlacement="top"
                onChange={changeHandler}
                isSearchable={true}
                placeholder="Please search or select your country."
            />
        </label>
    );
});

export default CountrySelect;