import React, {useState} from 'react';
import drivingLicenseImage from '../../Assets/images/driving-license-example.jpeg';
import Box from  "@mui/material/Box/Box";
import makeAjaxCall from "../Api/Verification";
import {FaQuestionCircle} from "react-icons/fa";
import {Tooltip} from "react-tooltip";
import { toast } from 'react-toastify';

const DrivingLicense = (parent) =>
{
    const [driving_license, setMessage] = useState('');
    const [middle_name, setMiddleName] = useState('');

    const handleChange = (value) => {
        setMessage(value);
    }

    const handleMiddleNameChange = (value) => {
        setMiddleName(value);
    }

    const handleClick = (event) => {
        event.preventDefault();
        const sendUserIdentity = async () => {
            if (typeof driving_license.target === 'undefined'
                || typeof driving_license.target.value === 'undefined') {
                toast.error('Please insert your driving licence number in the provided field.',{
                    position: 'top-right',
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: 'colored',
                });
                return false;
            }
            parent.showLoader();
            const response = await makeAjaxCall(
                driving_license,
                'driving_licence',
                null,
                null,
                null,
                middle_name
            );
            if (await response.data.result === "success") {
                parent.hideLoader();
                parent.setFinished(true);
            } else if (await response.data.result === "failure") {
                parent.hideLoader();
                parent.setFailure();
            }
        };
        return sendUserIdentity();
    }

    return (
        <Box>
            <form className="verification" id="driving_license" >
                <label htmlFor="middle-name">
                    {/* eslint-disable-next-line*/}
                    <span>Middle Name:</span><a className="middle-name-helper"><FaQuestionCircle fill="#24272A" className="question-inner" /></a>
                    <input type="text" onChange={handleMiddleNameChange} name="middle-name" placeholder="Middle Name"/>
                </label>
                <Tooltip anchorSelect=".middle-name-helper" position-strategy="fixed" place="top" style={{ zIndex: 999, opacity: 1, maxWidth: '100%' }} className="sons-tooltip" classNameArrow="sons-arrow">
                    <div style={{maxWidth: '350px', marginTop: '5px'}}>If you have a middle name, and this is present on your driving license, please add your middle name here.</div>
                    <div className="overlay"></div>
                </Tooltip>
                <label htmlFor="driving-license">
                    {/* eslint-disable-next-line*/}
                    <span>Driving License:</span><a className="driving-license-helper"><FaQuestionCircle fill="#24272A" className="question-inner" /></a>
                    <input type="text" onChange={handleChange} defaultValue={driving_license} name="driving-license" placeholder="Driving License Number"/>
                </label>
                <Tooltip anchorSelect=".driving-license-helper" position-strategy="fixed" place="top" style={{ zIndex: 999, opacity: 1, maxWidth: '100%' }} className="sons-tooltip" classNameArrow="sons-arrow">
                    <img className="" src={drivingLicenseImage} height="360" title="Driving License Example" alt="Driving License Example" style={{ paddingTop: '0', marginTop: '10px', border: '1px solid #24272a', borderRadius: '15px' }} />
                    <div style={{maxWidth: '350px', marginTop: '5px'}}><strong>Please Note:</strong> the driving license image, the information we require is presented beside point 5 of your driving license, minus the end 2 digits.</div>
                    <div className="overlay"></div>
                </Tooltip>
                <button type="submit" className="submit-button" onClick={handleClick}>
                    <span>Submit</span>
                </button>
            </form>
        </Box>
    )
}

export default DrivingLicense;