import getCustomerId from './getCustomerId';
import getSubscriptionId from './getSubscriptionId';
import axios from 'axios';

const makeAjaxCall = (identityData, type, expiryDate, passportUnique, country, middleName) =>
{
    // Sends verification data to endpoint.
    try {
        let middleNameActual = (middleName === null) ? '' : middleName.target.value;
        return axios.post('/api/verify',
            JSON.stringify({
                customerId: getCustomerId(),
                subscriptionId: getSubscriptionId(),
                type: type,
                identityData: identityData.target.value,
                expiryDate: expiryDate,
                uniqueMrz: passportUnique,
                country: country,
                middleName: middleNameActual
            }), {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    } catch (err) {
        console.error(err);
    }
}

export default makeAjaxCall;