import React, {useState, useRef} from 'react';
import makeAjaxCall from "../Api/Verification";
import 'react-tooltip/dist/react-tooltip.css';
import { Tooltip } from 'react-tooltip'
import passportImage from '../../Assets/images/passport-example.jpg';
import {FaQuestionCircle} from 'react-icons/fa';
import * as Icon from "react-icons/fi";
import Checkbox from "react-custom-checkbox";
import CountrySelect from "./Elements/CountrySelect";
import { toast } from 'react-toastify';

const Passport = (parent) =>
{
    const [passport, setMessage] = useState('');
    const [passportUnique, setPassportUnique] = useState('');
    const [passportUniqueShow, setPassportUniqueShow] = useState(false);
    const [passportExpiryDate, setPassportExpiryDate] = useState('');
    const [labelValue, setLabelValue] = useState('I hold a British passport.')

    const countryRef = useRef();

    const handleChange = (value) => {
        setMessage(value);
    }

    const handleExpiryChange = (value) => {
        setPassportExpiryDate(value);
    }

    const handleNonUkChange = (value) => {
        setPassportUnique(value);
    }

    const handleClick = (event) => {
        event.preventDefault();
        const sendUserIdentity = async () => {
            const toastOptions = {
                position: 'top-right',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'colored',
            };

            if (typeof passport.target === 'undefined'
                || typeof passport.target.value === 'undefined'
                || typeof passportExpiryDate.target === 'undefined'
                || typeof passportExpiryDate.target.value === 'undefined') {
                toast.error(
                    'Please insert your passport document number and expiry date in the provided field.',
                    toastOptions
                );
                return false;
            }

            const country = (typeof countryRef.current !== 'undefined') ? countryRef.current.getSelectedValue() : null;
            if (passportUniqueShow
                && typeof country.label !== 'undefined'
                && country.label === 'Please search or select your country.') {
                toast.error(
                'Please search or select your country from the dropdown list.',
                    toastOptions
                );
                return false;
            }

            if (typeof passport !== 'undefined'
                && typeof passport.target !== 'undefined'
                && typeof passport.target.value !== 'undefined'
                && passport.target.value.length < 9) {
                toast.error(
                    'Your passport number is exactly 9 characters long, detected ' + passport.target.value.length + ' characters, please check and retry.',
                    toastOptions
                );
                return false;
            }

            if (passportUniqueShow
                && typeof passportUnique.target !== 'undefined'
                && typeof passportUnique.target.value !== 'undefined'
                && passportUnique.target.value.length !== 16) {
                toast.error(
                    'The unique mrz field is exactly 16 characters long, detected ' + passportUnique.target.value.length + ' characters, please check and retry.',
                    toastOptions
                );
                return false;
            }

            parent.showLoader();
            let passportUniqueValue = (typeof passportUnique.target !== 'undefined') ? passportUnique.target.value : '';
            let countryResult = (country !== null) ? country.label : '';
            const response = await makeAjaxCall(
                passport,
                'passport',
                passportExpiryDate.target.value,
                passportUniqueValue,
                countryResult,
                null
            );

            if (await response.data.result === "success") {
                parent.hideLoader();
                parent.setFinished(true);
            } else if (await response.data.result === "failure") {
                parent.hideLoader();
                parent.setFailure();
            }
        };
        return sendUserIdentity();
    }

    return (
        <form className="verification" id="passport">
            <label htmlFor="passport">
                <span>Passport:</span>
                <input type="text" onChange={handleChange} defaultValue={passport} name="passport" placeholder="Passport Number" readOnly={false} />
            </label>
            <label htmlFor="passport-expiry-date">
                <span>Passport Expiry:</span>
                <input type="date" onChange={handleExpiryChange} defaultValue={passportExpiryDate} name="passport" placeholder="Passport Expiry Date" readOnly={false} />
            </label>
            <Checkbox
                icon={<Icon.FiCheck color="#f0fad7" size={16} />}
                name="is-non-uk"
                id="is-non-uk"
                checked={true}
                onChange={(value, event) => {
                    if (!value) {
                        setLabelValue('I hold an international passport.');
                    } else {
                        setLabelValue('I hold a British passport.');
                    }
                    setPassportUniqueShow(!value);
                }}
                borderColor="#24272a"
                style={{ cursor: 'pointer', marginTop: 5, background: '#24272a'  }}
                labelStyle={{ marginLeft: 10, marginTop: 4, userSelect: "none" }}
                label={labelValue}
            />
            <Tooltip anchorSelect=".non-uk-helper" place="top" position-strategy="fixed" style={{ zIndex: 999, opacity: 1, maxWidth: '100%' }} className="sons-tooltip" classNameArrow="sons-arrow">
                <img className="passport-example" src={passportImage} height="360" title="Passport MRZ Example" alt="Passport MRZ Example" style={{ paddingTop: '10px' }} />
                <div style={{paddingTop: '5px', maxWidth: 350}}><strong>Please Note:</strong> the boxed element on the bottom right of the photo id page, this section is 16 characters long. Please include the arrow (&lt;) symbols.</div>
                <div className="overlay"></div>
            </Tooltip>
            {passportUniqueShow && (
                <label htmlFor="unique-mrz-field" style={{marginTop: 10, display: 'block'}}>
                    {/* eslint-disable-next-line*/}
                    <span>Unique MRZ Field</span> <a className="non-uk-helper"><FaQuestionCircle fill="#24272A" className="question-inner" /></a>
                    <input type="text" onChange={handleNonUkChange} defaultValue={passportUnique} placeholder="Unique MRZ Field" name="is-non-uk" id="is-non-uk" readOnly={false}/>
                </label>
            )}
            {passportUniqueShow && (
                <CountrySelect ref={countryRef} />
            )}
            <button type="button"  className="submit-button" onClick={handleClick}>
                <span>Submit</span>
            </button>
        </form>
    )
}

export default Passport;